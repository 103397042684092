<template>
    <div class="newjobbox">
        <div class="searchText">共有<span class="text-xxl text-bold" style="color: #298dff;">"{{ key }}"</span>相关招聘大厅{{ total }}个</div>
        <div v-loading="loading">
            <div class="newjobCont flex align-center flex-wrap" >
                <div class="newjobItem text-white flex flex-direction justify-between" v-for="item in list">
                    <div class="padding-sm margin-top-xs">
                        <div class="text-xl margin-bottom-sm">{{ item.workName }}</div>
                        <div class="text-df margin-top-xs"><span style="opacity: 0.8; margin-right: 6px;">薪资</span><span>{{ item.salaryName }}</span></div>
                        <div class="address text-df margin-top-xs"><span style="opacity: 0.8;margin-right: 6px;">地点</span>
                            <span>上饶广信区</span>
<!--                            <span>{{ item.address }}</span>-->
                        </div>
                        <div class="gotoBtn flex align-center justify-center cursor-pointer" @click="gotoDating(item)">招聘大厅人数：{{ item.joinUserNumber }}/12人</div>
                    </div>
                    <div class="newjobItembot flex align-center">
<!--                        <el-avatar shape="square" :size="20" src="https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png"></el-avatar>-->
                        <span class="margin-left-xs">欢迎进入{{ item.workName }}招聘大厅</span>
                    </div>
                </div>
            </div>
            <div class="pagebox">
                <app-pagination
                    @handleCurrentChange="handleCurrentChange"
                    :pageSize="searchForm.pageSize"
                    :total="total"
                ></app-pagination>
            </div>

        </div>
    </div>
</template>
<script>
import _api from '@/api/index'
import AppPagination from "../../../components/pagination/AppPagination";
export default{
    components:{
        AppPagination,
    },
    data(){
        return{
            key:null,
            loading:false,
            list:[],
            searchForm:{
                pageSize: 12,
                pageNum: 1,
            },
            total:0,
        }
    },
    created() {
        //workName
        this.key=this.$route.query.key
        this.getList()
    },
    methods:{
        gotoDating(item){//去大厅界面
            if(item.joinUserNumber>=8){
                this.$message.warning("招聘大厅人数已满！")
                return
            }

            const {href} = this.$router.resolve({
                path: '/user/tempInterview',
                query: {
                    workId: item.id,
                }
            })
            window.open(href, '_blank')

            // this.$router.push({path:"/user/tempInterview",query:{workId: item.id}})

        },
        handleCurrentChange(num) {

            this.searchForm.pageNum = num;
            this.getList();
        },
        getList(){//获取列表
            this.loading=true
            _api.post("/app/work/queryWork",{...this.searchForm,workName:this.key}).then(res=>{
                this.loading=false
                if(res.code===200){
                    this.list=res.data.list
                    this.total=res.data.total
                }
            })
        }
    }
}
</script>
<style scoped>
.newjobbox{height: calc(100vh - 200px); overflow-y: auto;}
.newjobCont{width: 1200px; margin: 0 auto 0 auto; padding-top: 20px;}
.newjobItem{ width: 285px; height: 253px; margin-bottom: 20px; margin-right: 20px; border-radius: 6px; overflow: hidden; background: url("../../../assets/jobbg.png") no-repeat top center;}
.newjobItem:nth-child(4n){ margin-right: 0;}
.gotoBtn{border-radius: 24px;
    margin-top: 10px;
    background: rgba(56, 133, 242, 1);
    box-shadow:inset 1px 2px 4px 0px rgba(6, 83, 191, 1);
    backdrop-filter: blur(2px); height: 36px; font-size: 17px;}
.newjobItembot{ height: 40px; background: #3971e3; padding: 0 10px;}
.searchText{width: 1200px; margin: 0 auto 0 auto;padding-top: 20px;}
.address{ height: 50px;}
.pagebox{ width: 1200px; margin: 0 auto 0 auto; text-align: center;}



</style>


